import axios from 'axios';

export const BASE_URL = 'https://difaksa-server.onrender.com/api/v1/';
// export const BASE_URL = 'http://localhost:8000/api/v1/';

export const clubLogin = async (clubId, password) => {
	const response = await axios.post(BASE_URL + 'club/login', {
		clubId,
		password,
	});
	return response;
};

export const getClubDetails = async (clubId) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(BASE_URL + 'club/get-self-details', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return response;
};

export const getPlayers = async (clubId, active) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(BASE_URL + `club/${clubId}/players`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: { active },
	});
	return response;
};

export const getPlayerByRegNo = async (clubId, regNo) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(
		BASE_URL + `club/${clubId}/players/${regNo}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const createPlayer = async (body) => {
	let token = localStorage.getItem('token');
	return await axios.post(BASE_URL + `club/create-player`, body, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const uploadPlayerIqama = async (file, s3Id) => {
	let token = localStorage.getItem('token');
	try {
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post(
			BASE_URL + `club/player/upload-iqama/` + s3Id,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const uploadPlayerPassport = async (file, s3Id) => {
	let token = localStorage.getItem('token');
	try {
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post(
			BASE_URL + `club/player/upload-passport/` + s3Id,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const uploadPlayerDifaForm = async (file, s3Id) => {
	let token = localStorage.getItem('token');
	try {
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post(
			BASE_URL + `club/player/upload-registration-form/` + s3Id,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const uploadPlayerAffidavit = async (file, s3Id) => {
	let token = localStorage.getItem('token');
	try {
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post(
			BASE_URL + `club/player/upload-affidavit/` + s3Id,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const uploadPlayerTransferForm = (file, s3Id) => {
	const token = localStorage.getItem('token');

	try {
		const formData = new FormData();
		formData.append('file', file);

		return axios.post(
			BASE_URL + `club/player/upload-transfer-document/` + s3Id,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
	} catch (e) {
		return e;
	}
};

export const uploadPlayerImage = async (file, s3Id) => {
	let token = localStorage.getItem('token');
	try {
		const formData = new FormData();
		formData.append('photo', file);

		const response = await axios.post(
			BASE_URL + `club/player/upload-photo/${s3Id}`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const uploadClubLogo = async (file, s3Id) => {
	let token = localStorage.getItem('token');
	try {
		const formData = new FormData();
		formData.append('logo', file);

		const response = await axios.post(
			BASE_URL + `club/upload-logo/` + s3Id,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'content-type': 'multipart/form-data',
				},
			},
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const getSignedDocument = async (fileiD) => {
	let token = localStorage.getItem('token');
	try {
		const response = await axios.get(
			BASE_URL + `club/get-document-signed-link?token=${token}&key=${fileiD}`,
		);
		return response;
	} catch (e) {
		return e;
	}
};

export const updateClub = async (body) => {
	let token = localStorage.getItem('token');
	try {
		const response = await axios.patch(BASE_URL + `club/edit`, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response;
	} catch (error) {
		return error;
	}
};

export const getRegistrationRequests = async (clubId) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(
		BASE_URL + `club/${clubId}/register-player-requests`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const getCurrentDocumentsConfig = async () => {
	let token = localStorage.getItem('token');
	const response = await axios.get(BASE_URL + `club/documents-config`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return response;
};

export const getAllClubsList = async () => {
	return await axios.get(BASE_URL + `club/all-clubs`);
};

export const getTransferRequests = async (limit, offset, searchText) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(
		BASE_URL + `club/pending-transfer-requests`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				limit,
				offset,
				searchText,
			},
		},
	);
	return response;
};

export const checkPlayerByRegisterNumber = async (registrationNumber) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(
		BASE_URL + `club/check-player-by-registration-number/${registrationNumber}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const submitNewTransfer = async (transferParams) => {
	let token = localStorage.getItem('token');
	const response = await axios.post(
		BASE_URL + `club/create-new-transfer`,
		{ ...transferParams },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const getTransferById = async (clubId, transferId) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(
		BASE_URL + `club/pending-transfer-requests/${transferId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const changePlayerActiveState = async (playerId) => {
	let token = localStorage.getItem('token');
	try {
		const response = await axios.post(
			BASE_URL + `club/player/set-isActive/${playerId}`,
			null,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response;
	} catch (error) {
		return error;
	}
};

export const getPlayerRemark = async (playerId) => {
	let token = localStorage.getItem('token');
	const response = await axios.get(
		BASE_URL + `club/player/get-remarks/${playerId}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const savePlayer = async (body, playerId) => {
	let token = localStorage.getItem('token');

	const response = await axios.patch(
		BASE_URL + `club/edit-player/${playerId}`,
		body,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);

	return response;
};

export const updateTransfer = async (transferDocument, transferId) => {
	let token = localStorage.getItem('token');

	const response = await axios.patch(
		BASE_URL + `club/transfer/${transferId}`,
		{ transferDocument },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);

	return response;
};
