import { Input, Button, message, Card, Form, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { StyledLoginForm } from './styled/styledLoginForm';
import { clubLogin, getAllClubsList } from '../api/clubApi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { UserContext } from '../App';

export const LoginForm = () => {
	let navigate = useNavigate();
	const { setAuth } = useAuth();

	const [form] = StyledLoginForm.useForm();
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [clubsList, setClubsList] = useState([]);
	const [selectedClub, setSelectedClub] = useState('Login Using Your Club');

	const { club, setClub } = useContext(UserContext);

	const setData = (value, setItem) => {
		setItem(value);
	};

	const login = async () => {
		if (selectedClub === 'Login Using Your Club' && password === '') {
			message.error('Please select your club and enter your password!');
			return;
		} else if (selectedClub === 'Login Using Your Club') {
			message.error('Please select your club!');
			return;
		} else if (password === '') {
			message.error('Please enter your password!');
			return;
		}

		setLoading(true);
		try {
			const res = await clubLogin(selectedClub, password);

			if (res.status === 200) {
				message.success('Login Success');
				const token = res.data.token;
				localStorage.setItem('token', token);
				setClub(res.data.club);
				setAuth({ token, club });
				return navigate('/players', { replace: true });
			}
		} catch (err) {
			try {
				if (err.response.status === 401)
					message.error('Invalid Credentials! Try Again.');
				else message.error('Something went wrong! Please try Again.');
			} catch {
				message.error('Something went wrong! Please try Again.');
			}
		} finally {
			setLoading(false);
		}
	};

	const loadClubsList = async () => {
		setLoading(true);
		try {
			const res = await getAllClubsList();
			setClubsList(res.data.clubsList.rows);
		} catch (err) {
			message.error(
				'Failed to establish connection with the server. Please try again.',
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadClubsList();
	}, []);

	return (
		<Card
			style={{
				width: '30%',
				display: 'flex',
				justifyContent: 'center',
				border: '2px solid #ebebeb',
				borderRadius: '5px',
			}}
		>
			<Form
				form={form}
				name="login_form"
				style={{ display: 'contents', rowGap: '5px' }}
				labelCol={{ span: 5 }}
				wrapperCol={{ span: 24 }}
			>
				<Form.Item>
					<Select
						type="string"
						disabled={loading}
						defaultValue="Login Using Your Club"
						style={{ width: '100%' }}
						value={selectedClub}
						onChange={(value) => setSelectedClub(value)}
					>
						{clubsList.map((item) => (
							<Select.Option key={item.clubId} value={item.clubId}>
								{item.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[
						{
							required: true,
							message: 'Please input your password!',
						},
					]}
				>
					<Input.Password
						size="large"
						disabled={loading}
						placeholder="Password"
						value={password}
						onChange={(e) => {
							setData(e.target.value, setPassword);
						}}
					/>
				</Form.Item>
				<Form.Item wrapperCol={{ span: 24 }}>
					<Button
						type="primary"
						onClick={() => login()}
						style={{ width: '100%' }}
						disabled={loading}
					>
						Login
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};
