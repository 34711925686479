import {
	FullscreenOutlined,
	LeftCircleOutlined,
	LoadingOutlined,
	SaveOutlined,
} from '@ant-design/icons';
import { Button, Card, message, Image, Spin, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
	BASE_URL,
	createPlayer,
	uploadPlayerAffidavit,
	uploadPlayerDifaForm,
	uploadPlayerImage,
	uploadPlayerIqama,
	uploadPlayerPassport,
} from '../api/clubApi';
import { UserContext } from '../App';
import avatar from '../assets/avatar.png';
import {
	MAX_DOCUMENT_SIZE,
	MAX_IMAGE_SIZE,
	SUPPORTED_DOCUMENT_MIMETYPES,
	SUPPORTED_IMAGE_MIMETYPES,
} from '../constants';
import { StyledModal } from './styledModal';

export const NewRegistration = () => {
	const [player, setPlayer] = useState({
		fullName: '',
		email: '',
		phone: '',
		dateOfBirth: '',
		bloodGroup: '',
		iqamaNumber: '',
		passportNumber: '',
		homeAddress: '',
		saudiAddress: '',
		companyName: '',
		photo: '',
		iqama: '',
		passport: '',
		registrationForm: '',
		affidavit: '',
		s3Id: '',
	});

	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const photoRef = useRef(null);
	const registrationFormRef = useRef(null);
	const affidavitRef = useRef(null);
	const passportRef = useRef(null);
	const iqamaRef = useRef(null);

	let navigate = useNavigate();

	const { club, documentsConfig, loadDocumentsConfig } =
		useContext(UserContext);

	const isEmail = (email) => {
		return email
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};

	const isValid = () => {
		if (player.fullName.trim() === '') {
			message.warning('Please fill all the required fields!');
			return false;
		}

		if ((player.phone && player.phone.length < 5) || isNaN(player.phone)) {
			message.warning('Please enter a valid mobile number!');
			return false;
		}

		if (player.email && !isEmail(player.email)) {
			message.warning('Please enter a valid email address!');
			return false;
		}

		if (!documentsConfig) return false;

		if (documentsConfig.email && player.email.trim() === '') {
			message.warning("Please enter the player's email address!");
			return false;
		}

		if (documentsConfig.phone && player.phone.trim() === '') {
			message.warning("Please enter the player's mobile number!");
			return false;
		}

		if (documentsConfig.dateOfBirth && player.dateOfBirth.trim() === '') {
			message.warning("Please enter the player's date of birth!");
			return false;
		}

		if (documentsConfig.bloodGroup && player.bloodGroup.trim() === '') {
			message.warning("Please enter the player's blood group!");
			return false;
		}

		if (documentsConfig.homeAddress && player.homeAddress.trim() === '') {
			message.warning("Please enter the player's home address (India)!");
			return false;
		}

		if (documentsConfig.saudiAddress && player.saudiAddress.trim() === '') {
			message.warning("Please enter the player's address in Saudi Arabia!");
			return false;
		}

		if (documentsConfig.companyName && player.companyName.trim() === '') {
			message.warning("Please enter the player's company name!");
			return false;
		}

		if (documentsConfig.passportNumber && player.passportNumber.trim() === '') {
			message.warning("Please enter the player's passport number!");
			return false;
		}

		if (documentsConfig.iqamaNumber && player.iqamaNumber.trim() === '') {
			message.warning("Please enter the player's iqama number!");
			return false;
		}

		if (documentsConfig.photo && player.photo.trim() === '') {
			message.warning("Please upload the player's photo!");
			return false;
		}

		if (documentsConfig.passport && player.passport.trim() === '') {
			message.warning("Please upload the player's passport document!");
			return false;
		}

		if (documentsConfig.iqama && player.iqama.trim() === '') {
			message.warning("Please upload the player's iqama document!");
			return false;
		}

		if (documentsConfig.difaForm && player.registrationForm.trim() === '') {
			message.warning("Please upload the player's DIFA Registration Form!");
			return false;
		}

		if (documentsConfig.affidavit && player.affidavit.trim() === '') {
			message.warning("Please upload the player's affidavit!");
			return false;
		}

		if (player.iqama.trim() !== '' && player.iqamaNumber.trim() === '') {
			message.warning(
				'Please enter the iqama number for the uploaded document!',
			);
			return false;
		}

		if (player.passport.trim() !== '' && player.passportNumber.trim() === '') {
			message.warning(
				'Please enter the passport number for the uploaded document!',
			);
			return false;
		}

		setModalOpen(true);
		return true;
	};

	const onSelectPhoto = (e) => {
		const file = e.target.files[0];

		if (!file) {
			message.error('Please select a file to continue');
			return;
		}

		if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
			message.error('Please select an image file');
			return;
		}

		if (file.size > MAX_IMAGE_SIZE) {
			message.error('Only images with less than 500KB are allowed!');
			return;
		}

		setLoading(true);
		uploadPlayerImage(file, player.s3Id)
			.then((res) => {
				setPlayer({ ...player, photo: res.data.key });
			})
			.catch(() => {
				message.error('Something went wrong! Failed to save player photo.');
			})
			.finally(() => setLoading(false));
	};

	const onSelectDocument = (e) => {
		const file = e.target.files[0];

		if (!file) {
			message.error('Please select a file to continue');
			return;
		}

		if (!SUPPORTED_DOCUMENT_MIMETYPES.includes(file.type)) {
			message.error('Please select an image or PDF file');
			return;
		}

		if (file.size > MAX_DOCUMENT_SIZE) {
			message.error('Only files with less than 5MB are allowed!');
			return;
		}

		switch (e.target.id) {
			case 'registrationForm':
				setLoading(true);
				uploadPlayerDifaForm(file, player.s3Id)
					.then((res) => {
						setPlayer({ ...player, registrationForm: res.data.key });
					})
					.catch(() => {
						message.error(
							'Something went wrong! Failed to upload player DIFA registration form.',
						);
					})
					.finally(() => setLoading(false));
				break;
			case 'affidavit':
				setLoading(true);
				uploadPlayerAffidavit(file, player.s3Id)
					.then((res) => {
						setPlayer({ ...player, affidavit: res.data.key });
					})
					.catch(() => {
						message.error(
							'Something went wrong! Failed to upload player affidavit.',
						);
					})
					.finally(() => setLoading(false));
				break;
			case 'passport':
				setLoading(true);
				uploadPlayerPassport(file, player.s3Id)
					.then((res) => {
						setPlayer({ ...player, passport: res.data.key });
					})
					.catch(() => {
						message.error(
							'Something went wrong! Failed to save player passport.',
						);
					})
					.finally(() => setLoading(false));
				break;
			case 'iqama':
				setLoading(true);
				uploadPlayerIqama(file, player.s3Id)
					.then((res) => {
						setPlayer({ ...player, iqama: res.data.key });
					})
					.catch(() => {
						message.error('Something went wrong! Failed to save player iqama.');
					})
					.finally(() => setLoading(false));
				break;
			default:
				break;
		}
	};

	const getSignedDocumentLink = (link) => {
		const token = localStorage.getItem('token');
		return `${BASE_URL}club/get-player-document?token=${token}&key=${link}`;
	};

	const submit = async () => {
		if (!isValid()) {
			message.error('Please fill all required fields!');
			return false;
		}

		if (!club) return;

		setLoading(true);
		setSubmitting(true);
		try {
			await createPlayer({ ...player, password: '', clubId: club.clubId });
			message.success(
				'Player registration submitted successfully! Wait for the approval from Registration Desk and DIFA Officials',
			);
			navigate('/registration-requests');
		} catch (err) {
			if (err.response && err.response.status === 409) {
				const msg = err.response.data.message || 'Conflict error!';
				message.error(msg);
			} else message.error('Something went wrong! Please try Again.');
		} finally {
			setLoading(false);
			setSubmitting(false);
		}
	};

	const confirmModal = (
		<StyledModal
			title="Confirm Submission"
			visible={modalOpen}
			closable={false}
			destroyOnClose={true}
			cancelText="Cancel"
			okText="Confirm & Submit"
			okButtonProps={{
				type: 'primary',
				icon: submitting ? <LoadingOutlined /> : <SaveOutlined />,
				disabled: submitting || loading,
			}}
			onOk={submit}
			onCancel={() => setModalOpen(false)}
		>
			<p>
				The registration process will be submitted and will be reviewed by{' '}
				<b>DIFA Official</b> and <b>Registration Desk.</b>
			</p>
			<p>
				<b>Are you want to continue?</b>
			</p>
		</StyledModal>
	);

	useEffect(() => {
		loadDocumentsConfig();
		setPlayer({ ...player, s3Id: v4() });
	}, [club]);

	return documentsConfig ? (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ width: '70%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'left',
						gap: '1em',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'left',
							paddingBottom: '18px',
							flex: 0.01,
						}}
					>
						<Button
							icon={<LeftCircleOutlined />}
							style={{
								marginTop: '20px',
								border: '2px solid black',
								backgroundColor: 'transparent',
								color: 'black',
							}}
							onClick={() => {
								navigate(`/registration-requests`, { replace: true });
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'left',
							flex: 0.99,
							alignItems: 'center',
						}}
					>
						<h1
							style={{
								fontSize: 'large',
								fontWeight: 'bolder',
								marginTop: '2%',
								display: 'flex',
								justifyContent: 'flex-start',
							}}
						>
							NEW PLAYER REGISTRATION
						</h1>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								gap: '5px',
								alignItems: 'center',
								marginLeft: 'auto',
							}}
						>
							<Button
								type="primary"
								icon={<SaveOutlined />}
								disabled={loading || submitting}
								onClick={() => isValid()}
							>
								Submit
							</Button>
						</div>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						gap: '2em',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Card
						title={'Enter Player Details'}
						style={{
							width: '100%',
							marginBottom: '20px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '1em',
								width: '100%',
							}}
						>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card style={{ width: '100%' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'flex-end',
											gap: '1em',
											marginBottom: '25px',
										}}
									>
										<div
											style={{
												width: '100px',
												height: '100px',
											}}
										>
											<Image
												src={
													player.photo
														? BASE_URL + `get-player-image?key=${player.photo}`
														: avatar
												}
												width={'100%'}
											/>
										</div>
									</div>
									<Button
										disabled={loading}
										onClick={() => {
											photoRef.current.click();
										}}
										type="dashed"
									>
										<input
											type="file"
											id="photo"
											accept="image/*"
											ref={photoRef}
											onChange={onSelectPhoto}
											style={{ display: 'none' }}
										/>
										{player.photo ? 'Change Photo' : 'Upload Photo'}
									</Button>
								</Card>
							</div>

							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card
									title={
										<span>
											<label style={{ color: 'red' }}>{'* '}</label>
											Player Name (As Mentioned in Passport/Iqama)
										</span>
									}
									style={{ width: '100%' }}
								>
									<Input
										disabled={loading}
										value={player.fullName}
										onChange={(e) => {
											setPlayer({ ...player, fullName: e.target.value });
										}}
										placeholder="Enter Player Name"
									/>
								</Card>
							</div>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card
									title={
										<span>
											{documentsConfig.email && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Email
										</span>
									}
									style={{ width: '50%' }}
								>
									<Input
										disabled={loading}
										value={player.email}
										placeholder="johndoe@domain.com"
										onChange={(e) => {
											setPlayer({ ...player, email: e.target.value });
										}}
									/>
								</Card>
								<Card
									title={
										<span>
											{documentsConfig.phone && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Mobile Number
										</span>
									}
									style={{ width: '50%' }}
								>
									<Input
										disabled={loading}
										value={player.phone}
										placeholder="Please enter player's mobile number..."
										onChange={(e) => {
											setPlayer({ ...player, phone: e.target.value });
										}}
									/>
								</Card>
							</div>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card
									title={
										<span>
											{documentsConfig.dateOfBirth && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Date of Birth
										</span>
									}
									style={{ width: '50%' }}
								>
									<Input
										disabled={loading}
										value={player.dateOfBirth}
										onChange={(e) => {
											setPlayer({ ...player, dateOfBirth: e.target.value });
										}}
										maxLength={10}
										placeholder="Example: 31/12/1989"
									/>
								</Card>
								<Card
									title={
										<span>
											{documentsConfig.bloodGroup && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Blood Group
										</span>
									}
									style={{ width: '50%' }}
								>
									<Select
										defaultValue="None"
										value={player.bloodGroup}
										style={{ width: 120 }}
										onChange={(bloodGroup) => {
											setPlayer({ ...player, bloodGroup });
										}}
									>
										<Select.Option value="">None</Select.Option>
										<Select.Option value="A+">A+</Select.Option>
										<Select.Option value="A-">A-</Select.Option>
										<Select.Option value="B+">B+</Select.Option>
										<Select.Option value="B-">B-</Select.Option>
										<Select.Option value="AB+">AB+</Select.Option>
										<Select.Option value="AB-">AB-</Select.Option>
										<Select.Option value="O+">O+</Select.Option>
										<Select.Option value="O-">O-</Select.Option>
									</Select>
								</Card>
							</div>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card
									title={
										<span>
											{documentsConfig.homeAddress && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Home Address (India)
										</span>
									}
									style={{ width: '50%' }}
								>
									<TextArea
										rows={4}
										disabled={loading}
										value={player.homeAddress}
										onChange={(e) => {
											setPlayer({ ...player, homeAddress: e.target.value });
										}}
										placeholder="Enter the player's address in India"
									/>
								</Card>
								<Card
									title={
										<span>
											{documentsConfig.saudiAddress && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Saudi Address
										</span>
									}
									style={{ width: '50%' }}
								>
									<TextArea
										rows={4}
										disabled={loading}
										value={player.saudiAddress}
										onChange={(e) => {
											setPlayer({ ...player, saudiAddress: e.target.value });
										}}
										placeholder="Enter the player's residential address in Saudi Arabia"
									/>
								</Card>
							</div>
							<Card
								title={
									<span>
										{documentsConfig.companyName && (
											<label style={{ color: 'red' }}>{'* '}</label>
										)}
										Company Name
									</span>
								}
								style={{ width: '100%' }}
							>
								<Input
									disabled={loading}
									value={player.companyName}
									onChange={(e) => {
										setPlayer({ ...player, companyName: e.target.value });
									}}
									placeholder="Enter the player's employer / company name"
								/>
							</Card>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card
									title={
										<span>
											{documentsConfig.passportNumber && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Passport Number
										</span>
									}
									style={{ width: '50%' }}
								>
									<Input
										disabled={loading}
										value={player.passportNumber}
										onChange={(e) => {
											setPlayer({ ...player, passportNumber: e.target.value });
										}}
										placeholder="Enter Player Passport Number"
									/>
								</Card>
								<Card
									title={
										<span>
											{documentsConfig.iqamaNumber && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Iqama Number
										</span>
									}
									style={{ width: '50%' }}
								>
									<Input
										disabled={loading}
										value={player.iqamaNumber}
										onChange={(e) => {
											setPlayer({ ...player, iqamaNumber: e.target.value });
										}}
										placeholder="Enter Player Iqama Number"
									/>
								</Card>
							</div>
							<Card
								title={'Player Documents'}
								style={{
									width: '100%',
									borderRadius: '5px',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										gap: '1em',
										justifyContent: 'center',
									}}
								>
									<Card>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<div>
												{documentsConfig.registrationForm && (
													<label style={{ color: 'red' }}>{'* '}</label>
												)}
												DIFA Registration Form
												{!player.registrationForm && ' - No File Uploaded'}
											</div>
											<div style={{ marginLeft: 'auto' }}>
												{player.registrationForm && (
													<a
														className="link"
														href={
															player.registrationForm
																? getSignedDocumentLink(player.registrationForm)
																: null
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														<Button
															type="primary"
															icon={<FullscreenOutlined />}
															disabled={loading}
														>
															View
														</Button>
													</a>
												)}
												<Button
													style={{ marginLeft: '10px' }}
													type="dashed"
													onClick={() => {
														registrationFormRef.current.click();
													}}
													disabled={loading}
												>
													<input
														type="file"
														id="registrationForm"
														accept="image/*,application/pdf"
														ref={registrationFormRef}
														onChange={onSelectDocument}
														style={{ display: 'none' }}
													/>
													{player.registrationForm ? 'Change' : 'Upload'}
												</Button>
											</div>
										</div>
									</Card>

									<Card>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<div>
												{documentsConfig.affidavit && (
													<label style={{ color: 'red' }}>{'* '}</label>
												)}
												Affidavit
												{!player.affidavit && ' - No File Uploaded'}
											</div>
											<div style={{ marginLeft: 'auto' }}>
												{player.affidavit && (
													<a
														className="link"
														href={
															player.affidavit
																? getSignedDocumentLink(player.affidavit)
																: null
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														<Button
															type="primary"
															icon={<FullscreenOutlined />}
															disabled={loading}
														>
															View
														</Button>
													</a>
												)}
												<Button
													style={{ marginLeft: '10px' }}
													type="dashed"
													onClick={() => {
														affidavitRef.current.click();
													}}
													disabled={loading}
												>
													<input
														type="file"
														id="affidavit"
														accept="image/*,application/pdf"
														ref={affidavitRef}
														onChange={onSelectDocument}
														style={{ display: 'none' }}
													/>
													{player.affidavit ? 'Change' : 'Upload'}
												</Button>
											</div>
										</div>
									</Card>

									<Card>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<div>
												{documentsConfig.passport && (
													<label style={{ color: 'red' }}>{'* '}</label>
												)}
												Passport
												{!player.passport && ' - No File Uploaded'}
											</div>
											<div style={{ marginLeft: 'auto' }}>
												{player.passport && (
													<a
														className="link"
														href={
															player.passport
																? getSignedDocumentLink(player.passport)
																: null
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														<Button
															type="primary"
															icon={<FullscreenOutlined />}
															disabled={loading}
														>
															View
														</Button>
													</a>
												)}
												<Button
													style={{ marginLeft: '10px' }}
													type="dashed"
													onClick={() => {
														passportRef.current.click();
													}}
													disabled={loading}
												>
													<input
														type="file"
														id="passport"
														accept="image/*,application/pdf"
														ref={passportRef}
														onChange={onSelectDocument}
														style={{ display: 'none' }}
													/>
													{player.passport ? 'Change' : 'Upload'}
												</Button>
											</div>
										</div>
									</Card>

									<Card>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<div>
												{documentsConfig.iqama && (
													<label style={{ color: 'red' }}>{'* '}</label>
												)}
												Iqama / Saudi Visa Copy
												{!player.iqama && ' - No File Uploaded'}
											</div>
											<div style={{ marginLeft: 'auto' }}>
												{player.iqama && (
													<a
														className="link"
														href={
															player.iqama
																? getSignedDocumentLink(player.iqama)
																: null
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														<Button
															type="primary"
															icon={<FullscreenOutlined />}
															disabled={loading}
														>
															View
														</Button>
													</a>
												)}
												<Button
													style={{ marginLeft: '10px' }}
													type="dashed"
													onClick={() => {
														iqamaRef.current.click();
													}}
													disabled={loading}
												>
													<input
														type="file"
														id="iqama"
														accept="image/*,application/pdf"
														ref={iqamaRef}
														onChange={onSelectDocument}
														style={{ display: 'none' }}
													/>
													{player.iqama ? 'Change' : 'Upload'}
												</Button>
											</div>
										</div>
									</Card>
								</div>
							</Card>
						</div>
					</Card>
				</div>
				{confirmModal}
			</div>
		</div>
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '80vh',
			}}
		>
			<Spin style={{ fontSize: 'xx-large', color: 'blue' }} />
		</div>
	);
};
