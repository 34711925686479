import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const PersistentLogin = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { setAuth } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();

	let token;
	let club;

	useEffect(() => {
		token = localStorage.getItem('token');
		club = localStorage.getItem('club');

		if (!token && !club) {
			setIsLoading(true);
			setAuth(null);
			navigate('/login', { state: location, replace: true });
		} else {
			setAuth({ token, club });
			setIsLoading(false);
		}
	}, []);

	return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
};
