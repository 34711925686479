export const MAX_IMAGE_SIZE = 500 * 1024;

export const SUPPORTED_IMAGE_MIMETYPES = [
	'image/jpeg',
	'image/jpg',
	'image/png',
];

export const MAX_DOCUMENT_SIZE = 5 * 1000 * 1024;

export const SUPPORTED_DOCUMENT_MIMETYPES = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'application/pdf',
];
