import { Table, message, Input, Tag, Avatar, Empty, Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, getRegistrationRequests } from '../api/clubApi';
import { UserContext } from '../App';
import { StyledDiv } from './styled/styledDiv';
import avatar from '../assets/avatar.png';
import { PlusCircleOutlined } from '@ant-design/icons';
import { PlayerModal } from './playerAddModal';

export const RegistrationRequests = () => {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [isModalVisible, setModalVisible] = useState(false);

	const [data, setData] = useState(null);
	const [mainData, setMainData] = useState([]);
	const [playerCount, setPlayerCount] = useState();

	const { club } = useContext(UserContext);

	const getPlayerStatus = (status) => {
		const STATUSES = {
			0: 'Pending',
			1: 'On Hold',
			2: 'Approved',
			3: 'Verified',
		};

		const statusColors = {
			Verified: 'blue',
			Pending: 'gray',
			Approved: 'green',
			'On Hold': 'red',
		};

		return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
	};

	async function loadRequests() {
		if (!club) return;

		setLoading(true);
		getRegistrationRequests(club.clubId)
			.then((res) => {
				setPlayerCount(res.data.requests.count);
				if (res.data.requests.count > 0) {
					let rows = [];
					res.data.requests.rows.map((item) => {
						const player = {
							key: item.playerId,
							regno: item.registrationNumber,
							fullName: item.fullName,
							name: (
								<>
									<Avatar
										src={
											item.photo
												? BASE_URL + `get-player-image?key=${item.photo}`
												: avatar
										}
									/>
									<label style={{ paddingLeft: '15px' }}>{item.fullName}</label>
								</>
							),
							email: item.email || '-',
							status: getPlayerStatus(item.status),
							createdAt: new Date(item.createdAt).toLocaleDateString(),
						};
						rows.push(player);
					});
					setData(rows);
					setMainData(rows);
				}
			})
			.catch((err) => {
				message.error('Could not connect with the server. Please try again.');
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		loadRequests();
	}, [club]);

	const getIndividualPlayerDetails = (id) => {
		navigate(`/players/${id}`, { state: id });
	};

	const columns = [
		{
			title: 'Reg. Number',
			dataIndex: 'regno',
			width: '10%',
		},
		{
			title: 'Full Name',
			dataIndex: 'name',
			width: '30%',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '30%',
		},

		{
			title: 'Created At',
			dataIndex: 'createdAt',
			width: '10%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: '10%',
		},
	];

	const handleSearch = () => {
		if (searchText !== '') {
			let filterArray = [];
			mainData.forEach((item) => {
				const nameRecord = item.fullName.toLowerCase();
				if (nameRecord.includes(searchText.toLowerCase())) {
					const exists = filterArray.filter((keys) => keys.key === item.key);
					if (exists.length === 0) {
						filterArray.push(item);
					}
				}
				if (item.email?.includes(searchText)) {
					const exists = filterArray.filter((keys) => keys.key === item.key);
					if (exists.length === 0) {
						filterArray.push(item);
					}
				}
				if (item.regno.includes(searchText)) {
					const exists = filterArray.filter((keys) => keys.key === item.key);
					if (exists.length === 0) {
						filterArray.push(item);
					}
				}
			});
			setData(filterArray);
		} else {
			setData([...mainData]);
		}
	};

	const showModal = () => {
		setModalVisible(true);
	};

	useEffect(() => {
		handleSearch();
	}, [searchText]);

	const emptyComponent = {
		emptyText: (
			<Empty
				description={
					<span>
						{searchText === ''
							? 'No pending requests.'
							: 'No results matching your search input.'}
					</span>
				}
			/>
		),
	};

	return (
		<div>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<StyledDiv>
					<Input
						style={{ width: '80%' }}
						placeholder="Search using Register Number, Name or Email"
						onChange={(e) => {
							setSearchText(e.target.value);
						}}
					/>
					<Button
						style={{ width: '20%', minWidth: '200px' }}
						type="primary"
						icon={<PlusCircleOutlined />}
						// onClick={() => showModal()}
						onClick={() => {
							navigate('/registration-requests/new');
						}}
						disabled={loading}
					>
						New Registration
					</Button>
				</StyledDiv>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div
					style={{
						width: '90%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<div>
						<h1
							style={{
								fontSize: 'large',
								fontWeight: 'bolder',
								display: 'flex',
								justifyContent: 'flex-start',
							}}
						>
							REGISTRATIONS
							{!loading && ' (' + playerCount + ')'}
						</h1>
					</div>
					<Table
						columns={columns}
						locale={emptyComponent}
						loading={loading}
						dataSource={data}
						pagination={{ pageSize: 10 }}
						onRow={(record, index) => {
							return {
								onClick: (event) => {
									getIndividualPlayerDetails(record.regno);
								},
							};
						}}
					/>
				</div>
			</div>
			<PlayerModal
				isModalVisible={isModalVisible}
				setModalVisible={setModalVisible}
				loadRequests={loadRequests}
			/>
		</div>
	);
};
