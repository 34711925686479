import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Upload, message, Divider, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
	createPlayer,
	getCurrentDocumentsConfig,
	uploadPlayerAffidavit,
	uploadPlayerDifaForm,
	uploadPlayerImage,
	uploadPlayerIqama,
	uploadPlayerPassport,
} from '../api/clubApi';
import { UserContext } from '../App';
import { StyledModal } from './styledModal';

export const PlayerModal = ({
	isModalVisible = false,
	setModalVisible = () => '',
	loadRequests,
}) => {
	const { Option } = Select;

	const [loading, setLoading] = useState(false);

	const [documentsConfig, setDocumentsConfig] = useState(null);

	const [s3Id, setS3Id] = useState();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [bloodGroup, setBloodGroup] = useState('');
	const [iqamaId, setIqamaId] = useState('');
	const [iqamaNumber, setIqamaNumber] = useState('');
	const [iqama, setIqama] = useState([]);
	const [passportId, setPassportId] = useState('');
	const [passportNumber, setPassportNumber] = useState('');
	const [passport, setPassport] = useState([]);
	const [difaFormId, setDifaFormId] = useState('');
	const [difaForm, setDifaForm] = useState([]);
	const [affidavitId, setAffidavitId] = useState('');
	const [affidavit, setAffidavit] = useState([]);
	const [playerImageId, setPlayerImageId] = useState('');
	const [playerImage, setPlayerImage] = useState([]);
	const [previewImage, setPreviewImage] = useState();
	const [previewTitle, setPreviewTitle] = useState();
	const [previewVisible, setPreviewVisible] = useState(false);

	const { club } = useContext(UserContext);

	const getDocumentConfig = async () => {
		setLoading(true);
		getCurrentDocumentsConfig()
			.then((res) => {
				setDocumentsConfig(res.data.documentsConfig);
			})
			.catch((err) => {
				message.error('Failed to connect to the server. Please try again.');
				setModalVisible(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const clearFields = () => {
		setName('');
		setEmail('');
		setPhone('');
		setDateOfBirth('');
		setBloodGroup('');
		setPassport([]);
		setPassportId('');
		setIqama([]);
		setIqamaId('');
		setDifaForm([]);
		setDifaFormId('');
		setPlayerImage([]);
		setPlayerImageId('');
		setS3Id('');
		setPreviewImage('');
		setPreviewTitle('');
	};

	useEffect(() => {
		if (isModalVisible) {
			clearFields();
			setS3Id(v4());
		}
		getDocumentConfig();
	}, [isModalVisible]);

	const onChangeInput = (e, setFunc) => {
		setFunc(e.target.value);
	};

	const isEmail = (email) => {
		return email
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};

	const isValid = () => {
		if (name.trim() === '' || email.trim() === '' || phone.trim() === '') {
			return false;
		}

		if (phone.length < 5) {
			return false;
		}

		if (isNaN(phone)) {
			return false;
		}

		if (!isEmail(email)) {
			return false;
		}

		if (!documentsConfig) return false;

		if (documentsConfig.passport && passportId.trim() === '') return false;
		if (documentsConfig.iqama && iqamaId.trim() === '') return false;
		if (documentsConfig.difaForm && difaFormId.trim() === '') return false;
		if (documentsConfig.affidavit && affidavitId.trim() === '') return false;

		if (iqamaId.trim() !== '' && iqamaNumber.trim() === '') {
			return false;
		}

		if (passportId.trim() !== '' && passportNumber.trim() === '') {
			return false;
		}

		return true;
	};

	const submit = async () => {
		if (!isValid()) {
			message.error('Please fill all required fields!');
			return false;
		}

		let formData = {
			fullName: name,
			email: email,
			phone: phone,
			dateOfBirth,
			bloodGroup,
			password: '',
			clubId: club.clubId,
			photo: playerImageId,
			registrationForm: difaFormId,
			affidavit: affidavitId,
			passport: passportId,
			passportNumber,
			iqama: iqamaId,
			iqamaNumber,
			s3Id: s3Id,
		};

		setLoading(true);
		try {
			await createPlayer(formData);
			clearFields();
			setModalVisible(false);
			message.success(
				'Player registration submitted successfully! Wait for the approval from Registration Desk and DIFA Officials',
			);
			loadRequests();
		} catch (err) {
			if (err.response && err.response.status === 409)
				message.error('Another player with this email already exists!');
			else message.error('Something went wrong! Please try Again.');
		} finally {
			setLoading(false);
		}
	};

	const cancelModal = () => {
		clearFields();
		setModalVisible(false);
	};

	const onChangeIqama = ({ file }) => {
		setIqama(file);
		if (file.status === 'uploading') {
			file.status = 'done';
		}
	};

	const uploadIqama = async (options) => {
		const { file } = options;
		uploadPlayerIqama(file, s3Id).then((res) => {
			setIqamaId(res.data.key);
		});
	};

	const onChangePassport = ({ file }) => {
		setPassport(file);
		if (file.status === 'uploading') {
			file.status = 'done';
		}
	};

	const uploadPassport = async (options) => {
		const { file } = options;
		uploadPlayerPassport(file, s3Id).then((res) => {
			setPassportId(res.data.key);
		});
	};

	const onChangeForm = ({ file }) => {
		setDifaForm(file);
		if (file.status === 'uploading') {
			file.status = 'done';
		}
	};

	const uploadForm = async (options) => {
		const { file } = options;
		uploadPlayerDifaForm(file, s3Id).then((res) => {
			setDifaFormId(res.data.key);
		});
	};

	const onChangeAffidavit = ({ file }) => {
		setAffidavit(file);
		if (file.status === 'uploading') {
			file.status = 'done';
		}
	};

	const uploadAffidavit = async (options) => {
		const { file } = options;
		uploadPlayerAffidavit(file, s3Id).then((res) => {
			setAffidavitId(res.data.key);
		});
	};

	const onChangePlayerImage = ({ file }) => {
		setPlayerImage(file);
		if (file.status === 'uploading') {
			file.status = 'done';
		}
	};

	const uploadPlayerLogo = async (options) => {
		const { file } = options;
		uploadPlayerImage(file, s3Id).then((res) => {
			setPlayerImageId(res.data.key);
		});
	};

	function beforeUpload(file) {
		const isJpgOrPngOrPdf =
			file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPngOrPdf) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPngOrPdf && isLt2M;
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
		);
		setPreviewVisible(true);
	};

	const cancelPreview = () => {
		setPreviewVisible(false);
	};

	function beforeDocumentUpload(file) {
		const isJpgOrPngOrPdf =
			file.type === 'image/jpeg' ||
			file.type === 'image/png' ||
			'application/pdf';
		if (!isJpgOrPngOrPdf) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 5;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPngOrPdf && isLt2M;
	}

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	return (
		<StyledModal
			disabled={loading}
			destroyOnClose={true}
			title="Add Player"
			visible={isModalVisible}
			okText="Confirm"
			onOk={submit}
			okButtonProps={{ disabled: loading || !isValid() }}
			onCancel={cancelModal}
			cancelButtonProps={{ disabled: loading }}
		>
			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>Player Image</span>
					<Upload
						disabled={loading}
						maxCount={1}
						defaultFileList={playerImage}
						beforeUpload={beforeUpload}
						customRequest={uploadPlayerLogo}
						listType={'picture-card'}
						onChange={onChangePlayerImage}
						onPreview={handlePreview}
					>
						<PlusOutlined />
					</Upload>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						<label style={{ color: 'red' }}>{'* '}</label>
						Name
					</span>
					<Input
						disabled={loading}
						value={name}
						onChange={(e) => {
							onChangeInput(e, setName);
						}}
						placeholder="Enter Player Name"
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						<label style={{ color: 'red' }}>{'* '}</label>
						Email
					</span>
					<Input
						disabled={loading}
						value={email}
						onChange={(e) => {
							onChangeInput(e, setEmail);
						}}
						placeholder="Enter Player Email"
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						<label style={{ color: 'red' }}>{'* '}</label>
						Phone
					</span>
					<Input
						disabled={loading}
						value={phone}
						onChange={(e) => {
							onChangeInput(e, setPhone);
						}}
						maxLength={10}
						placeholder="Enter Player Phone"
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>Date Of Birth</span>
					<Input
						disabled={loading}
						value={dateOfBirth}
						onChange={(e) => {
							onChangeInput(e, setDateOfBirth);
						}}
						maxLength={10}
						placeholder="Example: 31/12/1989"
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>Blood Group</span>
					<Select
						defaultValue="None"
						value={bloodGroup}
						style={{ width: 120 }}
						onChange={(bloodGroup) => {
							setBloodGroup(bloodGroup);
						}}
					>
						<Option value="">None</Option>
						<Option value="A+">A+</Option>
						<Option value="A-">A-</Option>
						<Option value="B+">B+</Option>
						<Option value="B-">B-</Option>
						<Option value="AB+">AB+</Option>
						<Option value="AB-">AB-</Option>
						<Option value="O+">O+</Option>
						<Option value="O-">O-</Option>
					</Select>
				</div>
				<Divider />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						{documentsConfig && documentsConfig.iqama && (
							<label style={{ color: 'red' }}>{'* '}</label>
						)}
						Iqama
					</span>
					<Upload
						disabled={loading}
						beforeUpload={beforeDocumentUpload}
						maxCount={1}
						defaultFileList={iqama}
						customRequest={uploadIqama}
						onChange={onChangeIqama}
						onRemove={() => {
							setIqamaId('');
						}}
					>
						<Button disabled={loading}>Upload Iqama</Button>
					</Upload>
				</div>

				<div
					style={{
						display: iqamaId.trim() === '' ? 'none' : 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						<label style={{ color: 'red' }}>{'* '}</label>
						Iqama Number
					</span>
					<Input
						disabled={loading}
						value={iqamaNumber}
						onChange={(e) => {
							onChangeInput(e, setIqamaNumber);
						}}
						placeholder="Enter Player Iqama Number"
					/>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						{documentsConfig && documentsConfig.passport && (
							<label style={{ color: 'red' }}>{'* '}</label>
						)}
						Passport
					</span>
					<Upload
						disabled={loading}
						maxCount={1}
						beforeUpload={beforeDocumentUpload}
						defaultFileList={passport}
						customRequest={uploadPassport}
						onChange={onChangePassport}
						onRemove={() => {
							setPassportId('');
						}}
					>
						<Button disabled={loading}>Upload Passport</Button>
					</Upload>
				</div>

				<div
					style={{
						display: passportId.trim() === '' ? 'none' : 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						<label style={{ color: 'red' }}>{'* '}</label>
						Passport Number
					</span>
					<Input
						disabled={loading}
						value={passportNumber}
						onChange={(e) => {
							onChangeInput(e, setPassportNumber);
						}}
						placeholder="Enter Player Passport Number"
					/>
				</div>

				<Divider />

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						{documentsConfig && documentsConfig.registrationForm && (
							<label style={{ color: 'red' }}>{'* '}</label>
						)}
						DIFA Registration Form
					</span>
					<Upload
						disabled={loading}
						beforeUpload={beforeDocumentUpload}
						maxCount={1}
						defaultFileList={difaForm}
						customRequest={uploadForm}
						onChange={onChangeForm}
					>
						<Button disabled={loading}>Upload DIFA Registration Form</Button>
					</Upload>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '3%',
					}}
				>
					<span>
						{documentsConfig && documentsConfig.affidavit && (
							<label style={{ color: 'red' }}>{'* '}</label>
						)}
						Affidavit
					</span>
					<Upload
						disabled={loading}
						beforeUpload={beforeDocumentUpload}
						maxCount={1}
						defaultFileList={affidavit}
						customRequest={uploadAffidavit}
						onChange={onChangeAffidavit}
					>
						<Button disabled={loading}>Upload Affidavit</Button>
					</Upload>
				</div>
			</div>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				onCancel={cancelPreview}
				footer={null}
			>
				<img style={{ width: '100%' }} alt="preview" src={previewImage} />
			</Modal>
		</StyledModal>
	);
};
