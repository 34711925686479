import {
	EditFilled,
	FullscreenOutlined,
	LeftCircleOutlined,
} from '@ant-design/icons';
import { Card, message, Spin, Tag, Image, Button, Alert } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	BASE_URL,
	changePlayerActiveState,
	getPlayerByRegNo,
	getPlayerRemark,
} from '../api/clubApi';
import { UserContext } from '../App';
import avatar from '../assets/avatar.png';

const STATUSES = {
	pending: 0,
	onHold: 1,
	approved: 2,
	verified: 3,
};

export const IndividualPlayer = () => {
	const { registrationNumber } = useParams();
	const [player, setPlayer] = useState(null);
	const [remark, setRemark] = useState(null);
	const [loading, setLoading] = useState(false);

	const { club } = useContext(UserContext);

	let navigate = useNavigate();

	async function loadPlayer() {
		if (!club) return;
		getPlayerByRegNo(club.clubId, registrationNumber)
			.then((res) => {
				if (res.status === 200) {
					setPlayer(res.data.player);
					if (res.data.player.status === STATUSES.onHold)
						loadRemark(res.data.player.playerId);
				}
			})
			.catch((err) => {
				message.error('Could not connect with the server. Please try again.');
				console.error(err);
			});
	}

	async function loadRemark(playerId) {
		setLoading(true);
		getPlayerRemark(playerId)
			.then((res) => {
				if (res.status === 200) {
					setRemark(res.data.remark);
				}
			})
			.catch((err) => {
				message.error('Could not connect with the server. Please try again.');
				console.error(err);
			})
			.finally(() => setLoading(false));
	}

	async function toggleIsActive() {
		setLoading(true);
		changePlayerActiveState(player.playerId)
			.then(() => {
				setPlayer(null);
				loadPlayer();
			})
			.catch((err) => {
				message.error('Could not connect with the server. Please try again.');
				console.error(err);
			})
			.finally(() => setLoading(false));
	}

	useEffect(() => {
		loadPlayer();
	}, [club]);

	const getPlayerStatus = (status) => {
		const STATUSES = {
			0: 'Pending',
			1: 'On Hold',
			2: 'Approved',
			3: 'Verified',
		};

		const statusColors = {
			Verified: 'blue',
			Pending: 'gray',
			Approved: 'green',
			'On Hold': 'red',
		};

		return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
	};

	const getSignedDocumentLink = (link) => {
		const token = localStorage.getItem('token');
		return `${BASE_URL}club/get-player-document?token=${token}&key=${link}`;
	};

	return player ? (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ width: '70%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'left',
						gap: '1em',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'left',
							paddingBottom: '18px',
							flex: 0.01,
						}}
					>
						<Button
							icon={<LeftCircleOutlined />}
							style={{
								marginTop: '20px',
								border: '2px solid black',
								backgroundColor: 'transparent',
								color: 'black',
							}}
							onClick={() => {
								player && player.status === 3
									? navigate('/players', { replace: true })
									: navigate('/registration-requests', { replace: true });
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'left',
							flex: 0.99,
							alignItems: 'center',
						}}
					>
						<h1
							style={{
								fontSize: 'large',
								fontWeight: 'bolder',
								marginTop: '2%',
								display: 'flex',
								justifyContent: 'flex-start',
							}}
						>
							PLAYER DETAILS
						</h1>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								gap: '5px',
								alignItems: 'center',
								marginLeft: 'auto',
							}}
						></div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								gap: '5px',
								alignItems: 'center',
								marginLeft: 'auto',
							}}
						>
							{player.status === STATUSES.verified ? (
								// <Button
								// 	disabled={loading}
								// 	type={player.isActive ? 'outline' : 'primary'}
								// 	onClick={toggleIsActive}
								// >
								// 	{player.isActive
								// 		? 'Set Player Inactive'
								// 		: 'Set Player Active'}
								// </Button>
								<></>
							) : (
								player.status === STATUSES.onHold && (
									<Button
										type="dashed"
										danger
										icon={<EditFilled />}
										onClick={() => {
											navigate(`/players/${registrationNumber}/edit`);
										}}
									>
										Edit
									</Button>
								)
							)}
						</div>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						gap: '2em',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{!loading && player.status === STATUSES.onHold && (
						<Alert
							style={{ marginTop: '10px' }}
							message={`Registration has been withheld${
								remark ? ' with the follwing remark:' : ''
							}`}
							description={remark ? <i>{remark.remark}</i> : null}
							type="error"
							showIcon
						/>
					)}
					<Card
						title={
							<div style={{ width: '10%', height: '10%' }}>
								<Image
									src={
										player.photo
											? BASE_URL + `get-player-image?key=${player.photo}`
											: avatar
									}
									width={'100%'}
								/>
							</div>
						}
						style={{ borderRadius: '5px' }}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '1em',
							}}
						>
							<h2>{player.fullName}</h2>
							{player.status === STATUSES.verified && (
								<Tag
									style={{ marginBottom: '8px' }}
									color={player.isActive ? 'green' : 'default'}
								>
									{player.isActive ? 'Active' : 'Inactive'}
								</Tag>
							)}
						</div>
					</Card>
					<Card
						title={'Player Details'}
						style={{
							width: '100%',
							marginBottom: '20px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '1em',
								width: '100%',
							}}
						>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card title={'Registration Number'} style={{ width: '50%' }}>
									<div>
										<p>{player.registrationNumber}</p>
									</div>
								</Card>
								<Card title={'Status'} style={{ width: '50%' }}>
									<div>{getPlayerStatus(player.status)}</div>
								</Card>
							</div>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card title={'Email'} style={{ width: '50%' }}>
									<div>
										<p>{player.email || '-'}</p>
									</div>
								</Card>
								<Card title={'Mobile Number'} style={{ width: '50%' }}>
									<div>
										<p>{player.phone || '-'}</p>
									</div>
								</Card>
							</div>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card title={'Date of Birth'} style={{ width: '50%' }}>
									<div>
										<p>{player.dateOfBirth || '-'}</p>
									</div>
								</Card>
								<Card title={'Blood Group'} style={{ width: '50%' }}>
									<div>
										<p>{player.bloodGroup || '-'}</p>
									</div>
								</Card>
							</div>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card title={'Home Address (India)'} style={{ width: '50%' }}>
									<div>
										<p>{player.homeAddress || '-'}</p>
									</div>
								</Card>
								<Card title={'Saudi Address'} style={{ width: '50%' }}>
									<div>
										<p>{player.saudiAddress || '-'}</p>
									</div>
								</Card>
							</div>
							<Card title="Company Name" style={{ width: '100%' }}>
								<div>
									<p>{player.companyName || '-'}</p>
								</div>
							</Card>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card title={'Passport Number'} style={{ width: '50%' }}>
									<div>
										<p>{player.passportNumber || '-'}</p>
									</div>
								</Card>
								<Card title={'Iqama Number'} style={{ width: '50%' }}>
									<div>
										<p>{player.iqamaNumber || '-'}</p>
									</div>
								</Card>
							</div>
							<Card
								title={'Player Documents'}
								style={{
									width: '100%',
									borderRadius: '5px',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										gap: '1em',
										justifyContent: 'center',
									}}
								>
									<a
										className="link"
										href={
											player.registrationForm
												? getSignedDocumentLink(player.registrationForm)
												: null
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<button className="button-link">
											<p>
												DIFA Registration Form
												{!player.registrationForm && ' - No File Uploaded'}
											</p>
											<FullscreenOutlined />
										</button>
									</a>
									<a
										className="link"
										href={
											player.affidavit
												? getSignedDocumentLink(player.affidavit)
												: null
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<button className="button-link">
											<p>
												Affidavit{!player.affidavit && ' - No File Uploaded'}
											</p>
											<FullscreenOutlined />
										</button>
									</a>
									<a
										className="link"
										href={
											player.passport
												? getSignedDocumentLink(player.passport)
												: null
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<button className="button-link">
											<p>Passport{!player.passport && ' - No File Uploaded'}</p>
											<FullscreenOutlined />
										</button>
									</a>
									<a
										className="link"
										href={
											player.iqama ? getSignedDocumentLink(player.iqama) : null
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<button className="button-link">
											<p>Iqama / Saudi Visa Copy{!player.iqama && ' - No File Uploaded'}</p>
											<FullscreenOutlined />
										</button>
									</a>
								</div>
							</Card>
						</div>
					</Card>
				</div>
			</div>
		</div>
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '80vh',
			}}
		>
			<Spin style={{ fontSize: 'xx-large', color: 'blue' }} />
		</div>
	);
};
