import React, { useContext, useState } from 'react';
import {
	Link,
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { Button, Layout, Menu, Tooltip } from 'antd';
import {
	SettingOutlined,
	UserOutlined,
	SwapOutlined,
	LogoutOutlined,
	UsergroupAddOutlined,
	CopyOutlined,
} from '@ant-design/icons';
import { Players } from '../components/players';
import { Settings } from '../components/settings';
import { TransferWindow } from '../components/transferWindow';
import logo from '../assets/logo-dark.png';
import AuthContext from '../context/AuthProvider';
import { UpdateSettings } from '../components/updateSettings';
import { IndividualPlayer } from '../components/individualPlayer';
import { UserContext } from '../App';
import { RegistrationRequests } from '../components/registrationRequests';
import { StyledModal } from '../components/styledModal';
import { IndividualTransfer } from '../components/individualTransfer';
import { EditPlayer } from '../components/editPlayer';
import { NewRegistration } from '../components/newRegistration';
import { DifaDocuments } from '../components/difaDocuments';

const { Header, Content, Sider, Footer } = Layout;

export const Homepage = () => {
	const location = useLocation();

	const { documentsConfig } = useContext(UserContext);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header style={{ display: 'flex', alignItems: 'center', height: '16%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						alignItems: 'center',
						paddingTop: '20px',
						paddingBottom: '20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<img
							src={logo}
							alt="Club Logo"
							style={{
								height: '100px',
								marginTop: '10px',
								top: '10px',
								left: '10px'
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<LogOutBtn />
					</div>
				</div>
			</Header>

			<Layout>
				<Sider>
					<Menu
						theme="dark"
						defaultSelectedKeys={['/players']}
						selectedKeys={[location.pathname]}
					>
						<Menu.Item icon={<UserOutlined />} key={'/players'}>
							<span>Players</span>
							<Link to={'/players'} />
						</Menu.Item>

						<Menu.Item
							icon={<UsergroupAddOutlined />}
							key={'/registration-requests'}
						>
							<span>Registrations</span>
							<Link to={'/registration-requests'} />
						</Menu.Item>

						{documentsConfig && documentsConfig.transferOpen === true && (
							<Menu.Item icon={<SwapOutlined />} key={'/transfer'}>
								<span>Transfer Window</span>
								<Link to={'/transfer'} />
							</Menu.Item>
						)}

						<Menu.Item icon={<CopyOutlined />} key={'/difa-documents'}>
							<span>DIFA Documents</span>
							<Link to={'/difa-documents'} />
						</Menu.Item>

						<Menu.Item icon={<SettingOutlined />} key={'/settings'}>
							<span>Settings</span>
							<Link to={'/settings'} />
						</Menu.Item>
					</Menu>
				</Sider>

				<Content>
					<div>
						<Routes>
							<Route exact path="/players" element={<Players />} />
							<Route
								exact
								path="/players/:registrationNumber"
								element={<IndividualPlayer />}
							/>
							<Route
								exact
								path="/players/:registrationNumber/edit"
								element={<EditPlayer />}
							/>

							{documentsConfig && documentsConfig.transferOpen === true && (
								<>
									<Route exact path="/transfer" element={<TransferWindow />} />
									<Route
										exact
										path="/transfer/:transferId"
										element={<IndividualTransfer />}
									/>
								</>
							)}

							<Route
								exact
								path="/registration-requests"
								element={<RegistrationRequests />}
							/>
							<Route
								exact
								path="/registration-requests/new"
								element={<NewRegistration />}
							/>

							<Route exact path="/difa-documents" element={<DifaDocuments />} />

							<Route exact path="/settings" element={<Settings />} />
							<Route
								exact
								path="/settings/update"
								element={<UpdateSettings />}
							/>

							<Route path="*" element={<Navigate to="/players" replace />} />
						</Routes>
					</div>
				</Content>
			</Layout>

			<Footer
				style={{
					backgroundColor: '#00213C',
					color: 'white',
					textAlign: 'center',
				}}
			>
				© 2024 DIFA KSA |{' '}
				<b>
					<a
						href="https://teamartizen.com"
						style={{ color: 'white', textDecoration: 'none' }}
						target="_blank"
						rel="noopener noreferrer"
					>
						Artizen
					</a>
				</b>
			</Footer>
		</Layout>
	);
};

const LogOutBtn = () => {
	const { setAuth } = useContext(AuthContext);
	const { club, setClub } = useContext(UserContext);
	const navigate = useNavigate();

	const [modalOpen, setModalOpen] = useState(false);

	const handleLogOut = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('club');
		setClub(null);
		setAuth({});
		return navigate('/login', { replace: true });
	};

	const logOutDialog = (
		<StyledModal
			title="Confirm Logout"
			visible={modalOpen}
			closable={false}
			destroyOnClose={true}
			cancelText="Cancel"
			okText="Logout"
			okButtonProps={{ type: 'danger', ghost: true }}
			onOk={handleLogOut}
			onCancel={() => setModalOpen(false)}
		>
			Are you sure you want to logout?
		</StyledModal>
	);

	return (
		<>
			{logOutDialog}
			<h1 style={{ color: 'white', fontWeight: 'bolder' }}>
				{club && club.name}
			</h1>
			<Tooltip title="Logout">
				<Button
					icon={<LogoutOutlined />}
					style={{
						marginLeft: '15px',
						marginTop: '15px',
						border: '2px solid white',
						backgroundColor: 'transparent',
						color: 'white',
					}}
					onClick={() => setModalOpen(true)}
				/>
			</Tooltip>
		</>
	);
};
