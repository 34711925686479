import { EditOutlined } from '@ant-design/icons';
import { Card, Button, Spin, Image, message } from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, getClubDetails } from '../api/clubApi';
import { UserContext } from '../App';
import noLogo from '../assets/noLogoTemplate.jpeg';

export const Settings = () => {
	const { club, setClub } = useContext(UserContext);

	const navigate = useNavigate();
	const updateSettings = () => {
		navigate('/settings/update');
	};

	const loadClubDetails = async () => {
		let token = localStorage.getItem('token');
		if (token) {
			try {
				const res = await getClubDetails();
				setClub(res.data.club);
			} catch {
				message.error('Something went wrong! Please try again.');
			}
		}
	};

	useEffect(() => {
		loadClubDetails();
	}, []);

	return club ? (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ width: '90%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<h1
						style={{
							fontSize: 'large',
							fontWeight: 'bolder',
							marginTop: '2%',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						CLUB SETTINGS
					</h1>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: '1em',
							alignItems: 'center',
						}}
					>
						<Button
							icon={<EditOutlined />}
							onClick={() => updateSettings()}
							type="primary"
						>
							Edit
						</Button>
					</div>
				</div>

				<div
					style={{
						width: '100%',
						gap: '2em',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Card
						title={
							<div style={{ width: '10%', height: '10%' }}>
								<Image
									src={
										club.logo
											? BASE_URL + `get-club-logo?key=${club.logo}`
											: noLogo
									}
									width={'100%'}
								/>
							</div>
						}
					>
						<p style={{ fontWeight: 'bolder', fontSize: 'large' }}>
							{club.name}
							{club.code && ' | ' + club.code}
						</p>
					</Card>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
						<Card title={'Email'} style={{ width: '50%' }}>
							<div>
								<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
									{club.email || '-'}
								</p>
							</div>
						</Card>
						<Card title={'Phone'} style={{ width: '50%' }}>
							<div>
								<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
									{club.phone || '-'}
								</p>
							</div>
						</Card>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
						<div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
							<Card title={'Chairman'} style={{ width: '50%' }}>
								<div>
									<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
										{club.chairman || '-'}
									</p>
								</div>
							</Card>
							<Card title={'President'} style={{ width: '50%' }}>
								<div>
									<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
										{club.president || '-'}
									</p>
								</div>
							</Card>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '1em',
								marginBottom: '2em',
							}}
						>
							<Card title={'Treasurer'} style={{ width: '50%' }}>
								<div>
									<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
										{club.treasurer || '-'}
									</p>
								</div>
							</Card>
							<Card title={'Team Manager'} style={{ width: '50%' }}>
								<div>
									<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
										{club.teamManager || '-'}
									</p>
								</div>
							</Card>
							<Card title={'General Secretary'} style={{ width: '50%' }}>
								<div>
									<p style={{ fontWeight: 'bolder', fontSize: 'medium' }}>
										{club.generalSecretary || '-'}
									</p>
								</div>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '80vh',
			}}
		>
			<Spin style={{ fontSize: 'xx-large', color: 'blue' }} />
		</div>
	);
};
