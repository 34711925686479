import 'antd/dist/antd.min.css';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Homepage } from './pages/home';
import { LoginPage } from './pages/login';
import { Layout } from './components/layout';
import { RequireAuth } from './components/RequireAuth';
import { PersistentLogin } from './components/persistentLogin';
import { getClubDetails, getCurrentDocumentsConfig } from './api/clubApi';
import { message } from 'antd';
import AuthContext from './context/AuthProvider';

export const UserContext = createContext();

function App() {
	const [club, setClub] = useState(null);
	const { setAuth } = useContext(AuthContext);

	const [documentsConfig, setDocumentsConfig] = useState(null);

	const navigate = useNavigate();

	const loadClubDetails = async () => {
		const token = localStorage.getItem('token');
		if (token) {
			try {
				const res = await getClubDetails();
				setClub(res.data.club);
			} catch (err) {
				localStorage.removeItem('token');
				localStorage.removeItem('club');
				setClub(null);
				setAuth({});
				message.error('Something went wrong! Please try again.');
				return navigate('/login', { replace: true });
			}
		}
	};

	const loadDocumentsConfig = async () => {
		const token = localStorage.getItem('token');
		if (token) {
			try {
				const res = await getCurrentDocumentsConfig();

				setDocumentsConfig(res.data.documentsConfig);
			} catch (err) {
				setDocumentsConfig(null);
				localStorage.removeItem('token');
				message.error('Something went wrong! Please try again.');
				return navigate('/login', { replace: true });
			}
		}
	};

	useEffect(() => {
		loadClubDetails();
		loadDocumentsConfig();
	}, []);

	return (
		<UserContext.Provider
			value={{ club, setClub, documentsConfig, loadDocumentsConfig }}
		>
			<Routes>
				<Route element={<Layout />}>
					<Route path="/login" element={<LoginPage />} />

					<Route element={<PersistentLogin />}>
						<Route element={<RequireAuth />}>
							<Route path="/*" element={<Homepage />} />
						</Route>
					</Route>

					<Route
						path="*"
						element={<main className="app">404 Page not found!</main>}
					/>
				</Route>
			</Routes>
		</UserContext.Provider>
	);
}

export default App;
