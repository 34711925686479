import { Button, Card, Form, Image, Input, message } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import {
	BASE_URL,
	checkPlayerByRegisterNumber,
	submitNewTransfer,
	uploadPlayerTransferForm,
} from '../api/clubApi';
import { StyledModal } from './styledModal';
import avatar from '../assets/avatar.png';
import { UserContext } from '../App';
import { FullscreenOutlined } from '@ant-design/icons';
import { MAX_DOCUMENT_SIZE, SUPPORTED_DOCUMENT_MIMETYPES } from '../constants';

export const NewTransferModal = ({
	isModalVisible,
	setModalVisible,
	loadTransfers,
}) => {
	const [loading, setLoading] = useState(false);
	const [checkStatus, setCheckStatus] = useState('none');
	const [player, setPlayer] = useState(null);
	const [registerNumber, setRegisterNumber] = useState('');

	const { club, documentsConfig, loadDocumentsConfig } =
		useContext(UserContext);

	const [transferFormKey, setTransferFormKey] = useState(null); // Storage key
	const transferFormRef = useRef(null);

	function clearAll() {
		setPlayer(null);
		setCheckStatus('none');
		setRegisterNumber('');
		setTransferFormKey(null);
	}

	function cancelModal() {
		clearAll();
		setModalVisible(false);
	}

	function onSelectDocument(e) {
		const file = e.target.files[0];

		if (!file) {
			message.error('Please select a file to continue');
			return;
		}

		if (!SUPPORTED_DOCUMENT_MIMETYPES.includes(file.type)) {
			message.error('Please select an image or PDF file');
			return;
		}

		if (file.size > MAX_DOCUMENT_SIZE) {
			message.error('Only files with less than 5MB are allowed!');
			return;
		}

		setLoading(true);

		uploadPlayerTransferForm(file, player.s3Id)
			.then((res) => {
				setTransferFormKey(res.data.key);
			})
			.catch(() => {
				message.error('Something went wrong! Failed to save player iqama.');
			})
			.finally(() => setLoading(false));
	}

	async function createTransfer() {
		if (documentsConfig?.transferDocument && !transferFormKey) {
			message.warning('Please fill all the required fields!');
			return false;
		}

		setLoading(true);
		submitNewTransfer({
			playerName: player.fullName,
			playerEmail: player.email || '',
			playerRegistrationNumber: player.registrationNumber,
			fromClubId: player.clubId || '',
			fromClubName: player.club.name || '',
			fromClubLogo: player.club.logo || '',
			toClubId: club.clubId,
			toClubName: club.name,
			toClubLogo: club.logo || '',
			transferDocument: transferFormKey || null,
		})
			.then((res) => {
				setPlayer(res.data.player);
				message.success('Successfully submitted player transfer request!');
				setModalVisible(false);
				loadTransfers();
				clearAll();
			})
			.catch((err) => {
				if (err.response && err.response.status === 409) {
					message.error(err.response.data.message);
				} else {
					console.error(err);
					message.error('Something went wrong! Please try again.');
				}
			})
			.finally(() => setLoading(false));
	}

	async function getPlayerByRegisterNumber() {
		setLoading(true);
		checkPlayerByRegisterNumber(registerNumber)
			.then((res) => {
				setPlayer(res.data.player);

				message.success('Match found!');
				setCheckStatus('success');
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					setCheckStatus('error');
					message.error('No player with the register number found!');
					setPlayer(null);
				}
				console.error(err);
			})
			.finally(() => setLoading(false));
	}

	function getSignedDocumentLink(link) {
		const token = localStorage.getItem('token');
		return `${BASE_URL}club/get-player-document?token=${token}&key=${link}`;
	}

	useEffect(() => {
		loadDocumentsConfig();
	}, [isModalVisible]);

	return (
		<StyledModal
			disabled={loading}
			destroyOnClose={true}
			closable={false}
			title="Start New Player Transfer"
			visible={isModalVisible}
			okText="Confirm"
			onOk={createTransfer}
			okButtonProps={{ disabled: loading || checkStatus !== 'success' }}
			onCancel={cancelModal}
			cancelButtonProps={{ disabled: loading }}
		>
			<Form layout="vertical">
				<Form.Item
					label="Please enter the DIFA Register number of the player you want to invite:"
					hasFeedback
					validateStatus={checkStatus}
				>
					<Input.Group compact>
						<Input
							style={{ width: '80%' }}
							placeholder="Example: 221234"
							type="number"
							value={registerNumber}
							onChange={(e) => setRegisterNumber(e.target.value)}
							disabled={loading}
							help="No player with the register number found!"
						/>

						<Button
							style={{ width: '20%', minWidth: '70px' }}
							type="primary"
							onClick={getPlayerByRegisterNumber}
							disabled={registerNumber === '' || loading}
						>
							Check
						</Button>
					</Input.Group>
				</Form.Item>

				{player && (
					<Card title="Player Details">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: '1em',
							}}
						>
							<Image
								src={
									player.photo
										? BASE_URL + `get-player-image?key=${player.photo}`
										: avatar
								}
								style={{ borderRadius: '50%' }}
								width={'30%'}
							/>
							<h2>{player.fullName}</h2>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<Card style={{ width: '50%' }}>
								<h4>Register Number</h4>
								<div>
									<p>{player.registrationNumber}</p>
								</div>
							</Card>
							<Card style={{ width: '50%' }}>
								<h4>Current Club</h4>
								<div>
									<p>{player.club ? player.club.name : '-'}</p>
								</div>
							</Card>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<Card style={{ width: '50%' }}>
								<h4>Email</h4>
								<div>
									<p>{player.email || '-'}</p>
								</div>
							</Card>
							<Card style={{ width: '50%' }}>
								<h4>Phone</h4>
								<div>
									<p>{player.phone || '-'}</p>
								</div>
							</Card>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<Card style={{ width: '50%' }}>
								<h4>Date Of Birth</h4>
								<div>
									<p>{player.dateOfBirth || '-'}</p>
								</div>
							</Card>
							<Card style={{ width: '50%' }}>
								<h4>Blood Group</h4>
								<div>
									<p>{player.bloodGroup || '-'}</p>
								</div>
							</Card>
						</div>

						<Card style={{ marginTop: '20px' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<div>
									{documentsConfig?.transferDocument && (
										<label style={{ color: 'red' }}>{'* '}</label>
									)}
									Transfer Form
									{!transferFormKey && ' - No File Uploaded'}
								</div>
								<div style={{ marginLeft: 'auto' }}>
									{transferFormKey && (
										<a
											className="link"
											href={
												transferFormKey
													? getSignedDocumentLink(transferFormKey)
													: null
											}
											target="_blank"
											rel="noopener noreferrer"
										>
											<Button
												type="primary"
												icon={<FullscreenOutlined />}
												disabled={loading}
											>
												View
											</Button>
										</a>
									)}

									<Button
										style={{ marginLeft: '10px' }}
										type="dashed"
										onClick={() => {
											transferFormRef.current.click();
										}}
										disabled={loading}
									>
										<input
											type="file"
											id="transferForm"
											accept="image/*,application/pdf"
											ref={transferFormRef}
											onChange={onSelectDocument}
											style={{ display: 'none' }}
										/>

										{transferFormKey ? 'Change' : 'Upload'}
									</Button>
								</div>
							</div>
						</Card>
					</Card>
				)}
				<Form.Item></Form.Item>
			</Form>
		</StyledModal>
	);
};
