import { Input, Button, Spin, Card, Image, message, Modal } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	BASE_URL,
	clubLogin,
	updateClub,
	uploadClubLogo,
} from '../api/clubApi';
import { UserContext } from '../App';
import noLogoTemplate from '../assets/noLogoTemplate.jpeg';
import { MAX_IMAGE_SIZE, SUPPORTED_IMAGE_MIMETYPES } from '../constants';

export const UpdateSettings = () => {
	const navigate = useNavigate();

	const { club } = useContext(UserContext);
	const [currentPassword, setCurrentPassword] = useState('');
	const [unlocked, setUnlocked] = useState(false);

	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const photoRef = useRef(null);

	const [s3Id, setS3Id] = useState('');
	const [name, setName] = useState('');
	const [code, setCode] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [chairman, setChairman] = useState('');
	const [president, setPresident] = useState('');
	const [generalSecretary, setGeneralSecretary] = useState('');
	const [treasurer, setTreasurer] = useState('');
	const [teamManager, setTeamManager] = useState('');
	const [phone, setPhone] = useState('');
	const [logo, setLogo] = useState([]);
	const [logoUrl, setLogoUrl] = useState(club && club.logo);

	const [loading, setLoading] = useState(false);

	const [visible, setVisible] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);

	useEffect(() => {
		if (club) {
			setName(club.name);
			setCode(club.code);
			setEmail(club.email);
			setPresident(club.president);
			setChairman(club.chairman);
			setPhone(club.phone || '');
			setGeneralSecretary(club.generalSecretary);
			setTeamManager(club.teamManager);
			setTreasurer(club.treasurer);
			setPassword(club.password || '');
			setS3Id(club.s3Id);
		}
	}, [club]);

	const onCancel = () => {
		navigate('/settings', { replace: true });
	};

	function isValid() {
		if (password.trim() === '') {
			return false;
		}

		if (phone.length > 0 && phone.length < 5) {
			return false;
		}

		if (isNaN(phone)) {
			return false;
		}

		if (unlocked) {
			if (
				newPassword === '' ||
				confirmPassword === '' ||
				newPassword !== confirmPassword
			)
				return false;
		}

		return true;
	}

	const onSelectPhoto = (e) => {
		const file = e.target.files[0];

		if (!file) {
			message.error('Please select a file to continue');
			return;
		}

		if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
			message.error('Please select an image file');
			return;
		}

		if (file.size > MAX_IMAGE_SIZE) {
			message.error('Only images with less than 500KB are allowed!');
			return;
		}

		setLoading(true);
		uploadClubLogo(file, s3Id)
			.then((res) => {
				setLogoUrl(res.data.key);
			})
			.catch(() => {
				message.error('Something went wrong! Failed to save club logo.');
			})
			.finally(() => setLoading(false));
	};

	const onUpdate = () => {
		if (!isValid()) return false;

		let body = {
			name: name,
			password,
			clubId: club.clubId,
			phone,
			president,
			chairman,
			treasurer,
			teamManager,
			logo: logoUrl,
			generalSecretary,
		};

		if (unlocked) {
			body.password = newPassword;
		}

		setLoading(true);
		updateClub(body)
			.then((res) => {
				if (res.status >= 200 && res.status <= 299) {
					message.success('Successfully updated club details!');
					navigate('/settings', { replace: true });
				} else {
					message.error('Something went wrong! Please try again.');
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (e, func) => {
		func(e.target.value);
	};

	const onChangeClubImage = ({ file }) => {
		setLogo(file);
		if (file.status === 'uploading') {
			file.status = 'done';
		}
	};

	function beforeUpload(file) {
		const isJpgOrPngOrPdf =
			file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPngOrPdf) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPngOrPdf && isLt2M;
	}

	const uploadClubLogos = async (options) => {
		const { file } = options;
		uploadClubLogo(file, s3Id).then((res) => {
			setLogoUrl(res.data.key);
		});
	};

	const handleOk = () => {
		setModalLoading(true);
		clubLogin(club.clubId, currentPassword)
			.then(() => {
				setVisible(false);
				setUnlocked(true);
			})
			.catch((err) => {
				if (err.response && err.response.status === 401)
					message.error('Invalid credentials. Please try again.');
				else message.error('Something went wrong! Please try again.');
			})
			.finally(() => {
				setModalLoading(false);
			});
	};

	const handleCancel = () => {
		setVisible(false);
	};

	return club ? (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ display: 'flex', width: '90%', flexDirection: 'column' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<h1
						style={{
							fontSize: 'large',
							fontWeight: 'bolder',
							marginTop: '2%',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						EDIT CLUB DETAILS
					</h1>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: '1em',
							alignItems: 'center',
						}}
					>
						<Button
							onClick={onCancel}
							style={{ borderRadius: '5px' }}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							onClick={onUpdate}
							type="primary"
							style={{ borderRadius: '5px' }}
							disabled={loading || !isValid()}
						>
							Save Changes
						</Button>
					</div>
				</div>
				<div
					style={{
						width: '100%',
						gap: '2em',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Card>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '1em',
								width: '100%',
							}}
						>
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}
							>
								<Card style={{ width: '100%' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'flex-end',
											gap: '1em',
											marginBottom: '25px',
										}}
									>
										<div
											style={{
												width: '100px',
												height: '100px',
											}}
										>
											<Image
												src={
													logoUrl
														? BASE_URL + `get-club-logo?key=${logoUrl}`
														: noLogoTemplate
												}
												width={'100%'}
											/>
										</div>
									</div>
									<Button
										disabled={loading}
										onClick={() => {
											photoRef.current.click();
										}}
										type="dashed"
									>
										<input
											type="file"
											id="photo"
											accept="image/*"
											ref={photoRef}
											onChange={onSelectPhoto}
											style={{ display: 'none' }}
										/>
										{logoUrl ? 'Change Logo' : 'Upload Logo'}
									</Button>
								</Card>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '1em',
								paddingTop: '20px',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									gap: '0.5em',
								}}
							>
								<span>Club Name</span>
								<Input
									disabled
									value={name}
									onChange={(e) => {
										handleChange(e, setName);
									}}
								/>
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									gap: '0.5em',
								}}
							>
								<span>Code</span>
								<Input
									disabled
									value={code}
									onChange={(e) => {
										handleChange(e, setCode);
									}}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingTop: '10px',
								gap: '1em',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									gap: '0.5em',
								}}
							>
								<span>Email</span>
								<Input value={email} disabled />
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									gap: '0.5em',
									width: '50%',
								}}
							>
								<span>Phone</span>
								<Input
									disabled
									value={phone}
									onChange={(e) => {
										handleChange(e, setPhone);
									}}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingTop: '10px',
								gap: '1em',
							}}
						>
							{unlocked ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-around',
										gap: '0.5em',
										width: '100%',
									}}
								>
									<span>New Password</span>
									<Input
										placeholder="Please enter a new password"
										disabled={loading}
										status={
											newPassword !== '' && newPassword === confirmPassword
												? ''
												: 'error'
										}
										type="password"
										value={newPassword}
										onChange={(e) => {
											handleChange(e, setNewPassword);
										}}
									/>
									<span>Confirm New Password</span>
									<Input
										placeholder="Confirm your new password by entering it again"
										disabled={loading}
										status={
											newPassword !== '' && newPassword === confirmPassword
												? ''
												: 'error'
										}
										type="password"
										value={confirmPassword}
										onChange={(e) => {
											handleChange(e, setConfirmPassword);
										}}
									/>
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-around',
										gap: '0.5em',
										width: '100%',
									}}
								>
									<span>Password</span>
									<Input
										disabled={loading || !unlocked}
										type="password"
										value={password}
										onChange={(e) => {
											handleChange(e, setPassword);
										}}
									/>
									<Button
										onClick={() => {
											setVisible(true);
										}}
									>
										Change Password
									</Button>
								</div>
							)}
						</div>
					</Card>

					<Card
						title={'PERSONNEL INFO'}
						style={{ borderRadius: '5px', marginBottom: '2em' }}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								gap: '1em',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									width: '20%',
									flex: 1,
								}}
							>
								<span>Chairman</span>
								<Input
									disabled={loading}
									value={chairman}
									onChange={(e) => {
										handleChange(e, setChairman);
									}}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									width: '20%',
									flex: 1,
								}}
							>
								<span>President</span>
								<Input
									disabled={loading}
									value={president}
									onChange={(e) => {
										handleChange(e, setPresident);
									}}
								/>
							</div>
						</div>
						<div
							style={{
								paddingTop: '10px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								gap: '1em',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									flex: 1,
									width: '20%',
								}}
							>
								<span>Team Manager</span>
								<Input
									disabled={loading}
									value={teamManager}
									onChange={(e) => {
										handleChange(e, setTeamManager);
									}}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									flex: 1,
									width: '20%',
								}}
							>
								<span>Treasurer</span>
								<Input
									disabled={loading}
									value={treasurer}
									onChange={(e) => {
										handleChange(e, setTreasurer);
									}}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									flex: 1,
									width: '20%',
								}}
							>
								<span>General Secretary</span>
								<Input
									disabled={loading}
									value={generalSecretary}
									onChange={(e) => {
										handleChange(e, setGeneralSecretary);
									}}
								/>
							</div>
						</div>
					</Card>
				</div>
			</div>
			<Modal
				title="Please enter your current password"
				visible={visible}
				onOk={handleOk}
				okButtonProps={{
					disabled: currentPassword.length === 0,
				}}
				closable={false}
				confirmLoading={modalLoading}
				onCancel={handleCancel}
				cancelButtonProps={{ disabled: modalLoading }}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-around',
						flex: 1,
						width: '100%',
					}}
				>
					<Input
						type="password"
						disabled={modalLoading}
						value={currentPassword}
						onChange={(e) => {
							handleChange(e, setCurrentPassword);
						}}
					/>
				</div>
			</Modal>
		</div>
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '80vh',
			}}
		>
			<Spin style={{ fontSize: 'xx-large', color: 'blue' }} />
		</div>
	);
};
