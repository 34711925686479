import { LoginForm } from '../components/loginForm';
import logo from '../assets/logo-dark.png';
import loginBG from '../assets/loginBG.jpg';

export const LoginPage = () => {
	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				backgroundColor: 'black',
				background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${loginBG})`,
				backgroundSize: '100%',
			}}
		>
			<img
				src={logo}
				style={{ position: 'absolute', top: '15px', left: '10px' }}
			/>
			<div>
				<h1
					style={{ color: 'white', fontSize: 'xx-large', fontWeight: 'bolder' }}
				>
					CLUB LOGIN
				</h1>
			</div>
			<LoginForm />
		</div>
	);
};
