import { Button, Card } from 'antd';

import PLAYER_REGISTRATION_AND_TRANSFER_FORM from '../assets/DIFA_DOCS/PLAYERS_REGISTRATION_AND_TRANSFER_FORM_2023.pdf';
import DIFA_NOC from '../assets/DIFA_DOCS/DIFA_No_Objection_Letter.pdf';
import DIFA_PLAYERS_RELEASE_FORM from '../assets/DIFA_DOCS/DIFA_Players_Release_Form.pdf';
import CLUB_REGISTRATION_FORM from '../assets/DIFA_DOCS/CLUB_REGISTRATION_FORM.pdf';
import DIFA_NOTICE from '../assets/DIFA_DOCS/DIFA_NOTICE.pdf';

export const DifaDocuments = () => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ width: '90%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<h1
						style={{
							fontSize: 'large',
							fontWeight: 'bolder',
							marginTop: '2%',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						DIFA DOCUMENTS
					</h1>
				</div>

				<div
					style={{
						width: '100%',
						gap: '2em',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
						<Card
							style={{ width: '50%' }}
							title="Player Registration / Transfer Form"
						>
							<Button type="primary">
								<a href={PLAYER_REGISTRATION_AND_TRANSFER_FORM} target="_blank">
									Download File
								</a>
							</Button>
						</Card>

						<Card style={{ width: '50%' }} title="DIFA NOC">
							<Button type="primary">
								<a href={DIFA_NOC} target="_blank">
									Download File
								</a>
							</Button>
						</Card>

						<Card style={{ width: '50%' }} title="Players Release Form">
							<Button type="primary">
								<a href={DIFA_PLAYERS_RELEASE_FORM} target="_blank">
									Download File
								</a>
							</Button>
						</Card>
					</div>

					<div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
						<Card style={{ width: '50%' }} title="Club Registration Form">
							<Button type="primary">
								<a href={CLUB_REGISTRATION_FORM} target="_blank">
									Download File
								</a>
							</Button>
						</Card>

						<Card style={{ width: '50%' }} title="DIFA Notice">
							<Button type="primary">
								<a href={DIFA_NOTICE} target="_blank">
									DIFA NOTICE
								</a>
							</Button>
						</Card>
					</div>
				</div>
			</div>
		</div>
	);
};
